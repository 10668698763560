<template>
  <div class="page">
    <Navbar title="择偶条件" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-row class="row">
          <van-col span="6" class="name">身高</van-col>
          <van-col span="6">
            <van-field v-model="demand.stature.min" input-align="right"
          /></van-col>
          <van-col span="1" class="split">-</van-col>
          <van-col span="6">
            <van-field v-model="demand.stature.max" />
          </van-col>
          <van-col span="4" class="unit color-s1">厘米</van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6" class="name">体重</van-col>
          <van-col span="6">
            <van-field v-model="demand.weight.min" input-align="right"
          /></van-col>
          <van-col span="1" class="split">-</van-col>
          <van-col span="6">
            <van-field v-model="demand.weight.max" />
          </van-col>
          <van-col span="4" class="unit color-s1">公斤</van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6" class="name">年龄</van-col>
          <van-col span="6">
            <van-field v-model="demand.age.min" input-align="right"
          /></van-col>
          <van-col span="1" class="split">-</van-col>
          <van-col span="6">
            <van-field v-model="demand.age.max" />
          </van-col>
          <van-col span="4" class="unit color-s1">岁</van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6" class="name">个人月收入</van-col>
          <van-col span="6">
            <van-field v-model="demand.smi.min" input-align="right"
          /></van-col>
          <van-col span="1" class="split">-</van-col>
          <van-col span="6">
            <van-field v-model="demand.smi.max" />
          </van-col>
          <van-col span="4" class="unit color-s1">元</van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6" class="name">个人存款</van-col>
          <van-col span="6">
            <van-field v-model="demand.sd.min" input-align="right"
          /></van-col>
          <van-col span="1" class="split">-</van-col>
          <van-col span="6">
            <van-field v-model="demand.sd.max" />
          </van-col>
          <van-col span="4" class="unit color-s1">万元</van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6" class="name">房产数量</van-col>
          <van-col span="13">
            <van-field v-model="demand.house.amount" input-align="center"
          /></van-col>
          <van-col span="4" class="unit color-s1">套及以上</van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6" class="name">车辆数量</van-col>
          <van-col span="13">
            <van-field v-model="demand.car.amount" input-align="center"
          /></van-col>
          <van-col span="4" class="unit color-s1">辆及以上</van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6" class="name">学历</van-col>
          <van-col span="13">
            <van-field
              v-model="demand.qualify.name"
              readonly
              clickable
              placeholder="选择学历"
              input-align="center"
              @click="qualifyShow = true" />
            <van-popup :show="qualifyShow" position="bottom">
              <van-picker
                :columns="qualifys"
                :columns-field-names="{
                  text: 'name',
                }"
                @confirm="confirmQualify"
                @cancel="qualifyShow = false"
              /> </van-popup
          ></van-col>
          <van-col span="4" class="unit color-s1">及以上</van-col>
        </van-row>
        <van-row class="row">
          <van-col span="6" class="name">城市</van-col>
          <van-col span="13">
            <van-field
              v-model="demand.region.name"
              readonly
              clickable
              placeholder="选择城市"
              input-align="center"
              @click="regionShow = true" />
            <van-popup :show="regionShow" position="bottom">
              <van-area
                :area-list="regions"
                :columns-num="2"
                @confirm="confirmRegion"
                @cancel="regionShow = false"
              /> </van-popup
          ></van-col>
        </van-row>
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            :color="COLOR"
            size="small"
            icon="passed"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Form, Field, CellGroup, Picker, Popup, Area } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Loading: Loading,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Area.name]: Area
  },
  data () {
    return {
      personCode: '',
      regions: {},
      demand: {
        stature: { min: 110, max: 210 },
        weight: { min: 30, max: 100 },
        age: { min: 18, max: 70 },
        smi: { min: 2000, max: 30000 },
        sd: { min: 1, max: 200 },
        house: { amount: 1 },
        car: { amount: 1 },
        qualify: { code: '35', name: '初中' },
        region: { code: '610100', name: '陕西省/西安市' }
      },
      qualifys: [{ code: '', name: '未选择' }],
      regionShow: false,
      qualifyShow: false,
      loadingShow: false
    }
  },
  mounted () {
    var query = this.$route.query
    var code = query.personCode
    if (code !== '' && code !== undefined && code !== null) {
      this.personCode = code
      this.retrieveAttr()
    }
    this.retrieveRegions()
    this.retrieveQualifyOption()
    this.$refs.share.default('main')
  },
  methods: {
    confirmQualify (value) {
      this.demand.qualify.code = value.code
      this.demand.qualify.name = value.name
      this.qualifyShow = false
    },
    confirmRegion (values) {
      console.log(values)
      this.demand.region.name = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
      this.demand.region.code = values[1].code
      this.regionShow = false
    },
    async retrieveRegions () {
      var pd = { level: 2 }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveLevelRegion', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.regions.province_list = res.data.one
        this.regions.city_list = res.data.two
      }
    },
    async submit () {
      this.loadingShow = true
      var attrs = [
        { key: 'PSN_BAS_STATURE', type: 'DMD', content: this.demand.stature, seq: '1' },
        { key: 'PSN_BAS_WEIGHT', type: 'DMD', content: this.demand.weight, seq: '1' },
        { key: 'PSN_BAS_AGE', type: 'DMD', content: this.demand.age, seq: '1' },
        { key: 'PSN_AST_SMI', type: 'DMD', content: this.demand.smi, seq: '1' },
        { key: 'PSN_AST_SD', type: 'DMD', content: this.demand.sd, seq: '1' },
        { key: 'PSN_HOS_ITEM', type: 'DMD', content: this.demand.house, seq: '1' },
        { key: 'PSN_CAR_ITEM', type: 'DMD', content: this.demand.car, seq: '1' },
        { key: 'PSN_BAS_QUALIFY', type: 'DMD', content: this.demand.qualify, seq: '1' },
        { key: 'PSN_BAS_REGION', type: 'DMD', content: this.demand.region, seq: '1' }
      ]
      var pd = {
        personCode: this.personCode,
        attrs: JSON.stringify(attrs)
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '基础资料',
          message: '保存成功'
        }).then(() => {
          this.$router.go(-1)
        })
      } else {
        this.$dialog.alert({
          title: '基础资料',
          message: res.msg
        }).then(() => {
        })
      }
    },
    async retrieveAttr () {
      var keys = ['PSN_BAS_STATURE', 'PSN_BAS_WEIGHT', 'PSN_BAS_AGE', 'PSN_AST_SMI', 'PSN_AST_SD', 'PSN_HOS_ITEM', 'PSN_CAR_ITEM', 'PSN_BAS_QUALIFY', 'PSN_BAS_REGION']
      var pd = { personCode: this.personCode, keys: JSON.stringify(keys), type: 'DMD' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrKeysList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_BAS_STATURE') {
            this.demand.stature = obj
          } else if (element.key === 'PSN_BAS_WEIGHT') {
            this.demand.weight = obj
          } else if (element.key === 'PSN_BAS_AGE') {
            this.demand.age = obj
          } else if (element.key === 'PSN_AST_SMI') {
            this.demand.smi = obj
          } else if (element.key === 'PSN_AST_SMI') {
            this.demand.sd = obj
          } else if (element.key === 'PSN_HOS_ITEM') {
            this.demand.house = obj
          } else if (element.key === 'PSN_CAR_ITEM') {
            this.demand.car = obj
          } else if (element.key === 'PSN_BAS_QUALIFY') {
            this.demand.qualify = obj
          } else if (element.key === 'PSN_BAS_REGION') {
            this.demand.region = obj
          }
        })
      }
    },
    async retrieveRegionName () {
      var pd = { code: this.state.regionCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveRegionName', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.state.regionName = res.data.one + '/' + res.data.two + '/' + res.data.three
      }
    },
    async retrieveQualifyOption () {
      var pd = { key: 'QUALIFY_TYPE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.qualifys = this.qualifys.concat(res.data.item)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0px 5px;
  margin-bottom: 30px;
  .row {
    border-bottom: 1px solid #eee;
    .name {
      text-align: right;
      margin-top: 1px;
      font-size: 14px;
      height: 42px;
      line-height: 42px;
    }
    .split {
      height: 40px;
      line-height: 40px;
    }
    .unit {
      margin-top: 1px;
      font-size: 14px;
      height: 42px;
      line-height: 42px;
      text-align: left;
    }
  }
}
.title {
  font-size: 13px;
  padding: 8px 0px 0px 10px;
  font-weight: 600;
  text-align: left;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
